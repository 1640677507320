$(document).ready(function () {
    mobileMenu();
    let gameId = window.gameId;
    if (getCookie("vote_" + gameId)) {
        $(".rateit").css("pointer-events", "none");
    }
    $(".rateit").click(function () {
        newRate = $(this).rateit('value');
        if (!getCookie("vote_" + gameId)) {
            $(".rateit").css("pointer-events", "none");
            voteSubmit(gameId);
        }
    });
    $(".social__link").click(function () {
        shareCount(gameId);
    });
});

var mobileMenu = function() {
    var count = true;
    $('#hamburger').click(function() {

        if(count){
            $('.mobile').animate({
                left: '0px'
            }, 200);
            $('.mobile-head').animate({
                left: '265px'
            }, 200);
            count = false;
        }else{
            $('.mobile').animate({
                left: '-265px'
            }, 200);
            $('.mobile-head').animate({
                left: '0px'
            }, 200);
            count = true;
        }
    });
};
function getCookie( name ) {
    name = name.toLowerCase();
    const parts = document.cookie.split( ';' );

    for ( const part of parts ) {
        const pair = part.split( '=' );
        const key = decodeURIComponent( pair[ 0 ].trim().toLowerCase() );

        if ( key === name ) {
            return decodeURIComponent( pair[ 1 ] );
        }
    }

    return null;
}

function setCookie( name, value ) {
    document.cookie = encodeURIComponent( name ) + '=' + encodeURIComponent( value ) + ';path=/';
}

function voteSubmit(gameId) {
    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: '/game-vote',
        dataType: 'json',
        type: 'post',
        data: { gameRate: newRate, gameId: gameId},
        success: function (response) {
            if (response.success === true) {
                setCookie("vote_" + gameId, true);
            }
        }
    });
}
function shareCount(gameId) {
    $.ajax({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        url: '/game-shares',
        dataType: 'json',
        type: 'post',
        data: {gameId: gameId},
    });
}
